<template>
  <div class="page">
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide class="swiper-slide historique">
        <div class="content-slider">
          <div class="title" data-swiper-parallax="-100"><b>Historique des commandes</b></div>

        </div>
      </swiper-slide>
    </swiper>
    <div class=" body">
      <!-- ROW 1-->

      <div class="vx-row colors" style="margin-bottom: 30px;">
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3 cursor-pointer ">
          <vx-card
            :title="enAttente"
            subtitle="En attente"
            v-on:click.native="getCommands('EA')"
            :title-color="colors.white"
            :card-background="colors.green"
            :content-color="colors.white">
          </vx-card>
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3 cursor-pointer ">
          <vx-card
            :title="enCours"
            subtitle="En cours"
            :title-color="colors.white"
            :card-background="colors.primary"
            v-on:click.native="getCommands('EC')"
            :content-color="colors.white">
          </vx-card>
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3 cursor-pointer ">
          <vx-card
            :title="Clotures"
            subtitle="Cloturés"
            :title-color="colors.white"
            :card-background="colors.purple"
            v-on:click.native="getCommands('CLO')"
            :content-color="colors.white">
          </vx-card>
        </div>

      </div>

      <div class="vx-row colors">
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3 cursor-pointer ">
          <vx-card
            :title="creditBoutique"
            subtitle="Crédit boutique"
            :title-color="colors.white"
            :card-background="colors.blue"
            v-on:click.native="getCommands('CB')"
            :content-color="colors.white">
          </vx-card>
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3 cursor-pointer ">
          <vx-card
            :title="Suspendus"
            subtitle="Suspendus"
            :title-color="colors.white"
            :card-background="colors.ocre"
            v-on:click.native="getCommands('SB')"
            :content-color="colors.white">
          </vx-card>
        </div>
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3 cursor-pointer ">
          <vx-card
            :title="NonRenseigne"
            subtitle="Non renseigné"
            :title-color="colors.white"
            v-on:click.native="getCommands('NR')"
            :card-background="colors.NR"
            :content-color="colors.white">
          </vx-card>
        </div>

      </div>
      <!-- Tableau -->
      <vx-card>

        <vs-alert v-if="success == 'false'" color="danger" title="Erreur" active="true">
          Votre paiement a été refusé. Merci de vérifier vos donnés et réessayez.
        </vs-alert>
        <br><br>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">

          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ commandes.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : commandes.length }} of {{ commandes.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>

                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exporter csv</vs-button>
          </div>
        </div>
        <div class="mt-5">
          <vs-tabs>
            <vs-tab  label="Toutes" @click="getCommands()" >
            </vs-tab>
          </vs-tabs>
          <div class="tab-text">
            <ag-grid-vue
              ref="agGridTable"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="commandes"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl">
            </ag-grid-vue>
            <vs-pagination
              :total="totalPages"
              :max="maxPageNumbers"
              v-model="currentPage" />
          </div>
        </div>

      </vx-card>
    </div>
    </div>
</template>

<script>
import moment from 'moment';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { AgGridVue } from 'ag-grid-vue';
import axios from '../../axios.js';
import { colors } from '../../../themeConfig';
import store from '../../store/store';
import router from '../../router';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';

export default {
  props: ['success'],
  components: {
    swiper,
    swiperSlide,
    AgGridVue,
  },
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    statutConvert(value) {
      switch (value) {
        case 'EP':
          return 'En attente de paiement';
        case 'EA':
          return 'En attente';
        case 'SB':
          return 'Suspendue';
        case 'EC':
          return 'En cours';
        case 'CLO':
          return 'Cloturée';
        case 'NR':
          return 'Non renseigné';
        case 'RF':
          return 'Refusée';
        case 'CB':
          return 'Crédit boutique';
        default:
          return 'En cours';
      }
    },
  },
  data() {
    return {
      // Area charts
      first: '',
      last: '',
      next: '',
      searchQuery: '',
      idLigne: this.$route.query.id,
      gridOptions: {
        onRowClicked(event) {
          router.push({ name: 'user-orders-id', params: { id: event.data.id } });
          // this.details(event.data.id)
        },
        getRowNodeId(data) { return data.id; },
      },
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      enAttente: '',
      colors,
      Suspendus: '',
      enCours: '',
      Clotures: '',
      commandes: '',
      creditBoutique: '',
      NonRenseigne: '',
      columnDefs: [
        {
          headerName: 'Réference',
          field: 'reference',
          filter: true,
          width: 180,
        },
        {
          headerName: 'Formule',
          field: 'formule',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Nb.RDV',
          field: 'nbRdv',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Statut',
          field: 'statut',
          filter: false,
          cellRenderer(params) {
            let statut = '';
            switch (params.value) {
              case 'EP':
                statut = 'En attente de paiement';
                break;
              case 'EA':
                statut = 'En attente';
                break;
              case 'SB':
                statut = 'Suspendue';
                break;
              case 'EC':
                statut = 'En cours';
                break;
              case 'CLO':
                statut = 'Cloturée';
                break;
              case 'NR':
                statut = 'Non renseigné';
                break;
              case 'RF':
                statut = 'Refusée';
                break;
              case 'CB':
                statut = 'Crédit boutique';
                break;
              default:
                statut = 'En cours';
            }
            return `<div class="status"><span class="${params.value}">${statut}</span></div>`;
          },
          width: 250,
        },
        {
          headerName: 'Date de création',
          field: 'dateCreation',
          filter: true,
          cellRenderer(params) {
            if (params.value !== undefined && params.value !== null) {
              return moment(String(params.value)).format('DD/MM/YYYY HH:mm:ss');
            }
          },
          width: 300,
        },
        {
          headerName: 'Date de prise en charge',
          field: 'datePriseCharge',
          cellRenderer(params) {
            if (params.value !== undefined && params.value !== null) {
              return moment(String(params.value)).format('DD/MM/YYYY HH:mm:ss');
            }
          },
          filter: true,
          width: 300,
        },
      ],

    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    getCommands(statut) {
      // Les commandes
      let urlTotal = '';
      const { user } = store.state.AppActiveUser;
      if (statut == null) {
        urlTotal = `/api/commandes?trigramme=${user.trigramme}&order[dateCreation]`;
      } else {
        urlTotal = `/api/commandes?statut=${statut}&trigramme=${user.trigramme}&order[dateCreation]`;
      }

      axios.get(urlTotal, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.commandes = response.data['hydra:member'];
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    details(id) {
      router.push({ name: 'details', params: { id } });
    },
  },
  mounted() {
    const { user } = store.state.AppActiveUser;
    axios.get(`/api/commandes?statut=EA&trigramme=${user.trigramme}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.enAttente = String(response.data.length);
      })
      .catch(() => {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      });

    axios.get(`/api/commandes?statut=CB&trigramme=${user.trigramme}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.creditBoutique = String(response.data.length);
      })
      .catch(() => {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      });
    axios.get(`/api/commandes?statut=NR&trigramme=${user.trigramme}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.NonRenseigne = String(response.data.length);
      })
      .catch(() => {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      });
    axios.get(`/api/commandes?statut=EC&trigramme=${user.trigramme}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.enCours = String(response.data.length);
      });

    axios.get(`/api/commandes?statut=CLO&trigramme=${user.trigramme}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.Clotures = String(response.data.length);
      });

    axios.get(`/api/commandes?statut=SB&trigramme=${user.trigramme}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.Suspendus = String(response.data.length);
      });
    // Les commandes

    axios.get(`/api/commandes?trigramme=${user.trigramme}&order[dateCreation]`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.commandes = response.data;
      });
    this.gridApi = this.gridOptions.api;
  },
};
</script>
<style>
  .body {
    padding-top: 30px;
  }
  .swiper-slide.historique {
    background: url("../../assets/images/Slider/historique.png");
    background-size: cover;
  }
  .colors .vx-card__title {
    width: 100%;
    text-align: center;
  }

  .colors .vx-card__title h4 {
    width: 100%;
    padding-bottom: 10px;
    font-size: 38px;
  }

  .container {
    padding: 100px 0;
  }

  .colors .vx-card__title h6 {
    color: #fff !important;
  }

  .colors {
    margin-bottom: 50px
  }
  .ag-cell a {color : #000000}
  a.details  {color : #ffffff !important;}

</style>
